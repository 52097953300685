import { MQFormDateInputMinMax, MQFormDateInputType } from '../types';

export const generateMinMaxValues = (
  type: MQFormDateInputType,
  minValues: MQFormDateInputMinMax,
  maxValues: MQFormDateInputMinMax,
): { min: string; max: string } => {
  switch (type) {
    case 'date':
      return {
        min: `${minValues.year}-${minValues.month}-${minValues.day}`,
        max: `${maxValues.year}-${maxValues.month}-${maxValues.day}`,
      };
    case 'month':
      return {
        min: `${minValues.year}-${minValues.month}`,
        max: `${maxValues.year}-${maxValues.month}`,
      };
    default:
      return {
        min: `${minValues.hours}:${minValues.minutes}`,
        max: `${maxValues.hours}:${maxValues.minutes}`,
      };
  }
};

export const generateValidDateValue = (type: MQFormDateInputType, value: string) => {
  switch (type) {
    case 'month':
      return value?.split('-').slice(0, 2).join('-');
    case 'year':
      return value?.split('-').slice(0, 1).join('-');
    default:
      return value;
  }
};
