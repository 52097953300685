import './style.scss';

import { FC, useMemo } from 'react';

import classNames from 'classnames';

import { generateMinMaxValues, generateValidDateValue } from '../../helpers';
import { MQFormDateInputProps } from '../../types';

const MQFormDateInput: FC<MQFormDateInputProps> = ({
  isValid,
  isInvalid,
  stroke,
  type = 'date',
  minDate,
  maxDate,
  value,
  className,
  dataTestId = '',
  onChange,
  compact,
  ariaLabel = 'Date Input',
  name,
  ...props
}) => {
  const minValues = useMemo(
    () => ({
      year: `${minDate?.getFullYear() ?? new Date().getFullYear() - 100}`,
      month: `${(minDate?.getMonth() ?? 0) + 1}`.padStart(2, '0'),
      day: `${minDate?.getDate() ?? 1}`.padStart(2, '0'),
      hours: `${minDate?.getHours() ?? 1}`.padStart(2, '00'),
      minutes: `${minDate?.getMinutes() ?? 1}`.padStart(2, '00'),
    }),
    [minDate],
  );

  const maxValues = useMemo(
    () => ({
      year: `${maxDate?.getFullYear() ?? new Date().getFullYear() + 31}`,
      month: `${(maxDate?.getMonth() ?? 11) + 1}`.padStart(2, '0'),
      day: `${maxDate?.getDate() ?? 31}`.padStart(2, '0'),
      hours: `23`,
      minutes: `59`,
    }),
    [maxDate],
  );

  const minMaxValues = useMemo(() => generateMinMaxValues(type, minValues, maxValues), [maxValues, minValues, type]);
  const validValue = useMemo(() => generateValidDateValue(type, `${value}`), [type, value]);

  const testId = useMemo(() => [`mq-form-date-input`, name].join('-'), [name]);

  const meta = useMemo(() => {
    if (type === 'year') {
      const maxYear = Number(maxValues?.year);
      const minYear = Number(minValues?.year);
      return {
        type,
        options: Array.from({ length: maxYear - minYear + 1 }, (_, index) => minYear + index).map((year) => ({
          value: year,
          label: `${year}`,
        })),
      };
    }

    return {
      type,
    };
  }, [maxValues?.year, minValues?.year, type]);

  if (meta.type === 'year') {
    return (
      <select
        {...props}
        name={name}
        data-testid={dataTestId || testId}
        onChange={onChange}
        aria-label={ariaLabel}
        className={classNames(
          'mq-form-date-input',
          { ['is-valid']: isValid },
          { ['is-invalid']: isInvalid },
          { stroke },
          { compact },
          className,
        )}
        value={validValue}
      >
        <option disabled value="">
          ----
        </option>
        {meta.options.map((year) => (
          <option key={year.value} value={year.value}>
            {year.label}
          </option>
        ))}
      </select>
    );
  }

  return (
    <input
      {...props}
      data-testid={testId}
      className={classNames(
        'mq-form-date-input',
        { ['is-valid']: isValid },
        { ['is-invalid']: isInvalid },
        { stroke },
        { compact },
        className,
      )}
      aria-label={ariaLabel}
      type={meta.type}
      value={validValue}
      name={name}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
      }}
      min={minMaxValues.min}
      max={minMaxValues.max}
    />
  );
};

export default MQFormDateInput;
