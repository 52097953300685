import { FC, ReactNode, useEffect, useMemo } from 'react';

import {
  EnrollmentStateEnum,
  getEnrollmentCurrentStateThunk,
  getEnrollmentPreviousStateThunk,
  getInvitedProgramSelector,
  getInvitedProgramsThunk,
  invitedProgramsLoadingStateSelector,
  setEnrollmentNextStateThunk,
  skipEnrollmentThunk,
  startEnrollmentThunk,
} from '@mentorcliq/storage';

import ROUTES from 'routes';

import { AppEnrollContext, AppEnrollContextParams } from 'definitions/context';

import history from 'helpers/history';

import { useAppAuthState } from 'hooks/useAppAuthState';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppRouter } from 'hooks/useAppRouter';
import { useAppSelector } from 'hooks/useAppSelector';

import AppRedirect from 'router/AppRedirect';

interface EnrollProviderProps {
  children?: ReactNode;
}

const ENROLLMENT_PATHS = {
  [EnrollmentStateEnum.Initial]: ROUTES.enrollWizardOverview.data.path,
  [EnrollmentStateEnum.Welcome]: ROUTES.enrollWizardOverview.data.path,
  [EnrollmentStateEnum.PendingInvitations]: ROUTES.enrollWizardOverview.data.path,
  [EnrollmentStateEnum.RoleOverview]: ROUTES.enrollWizardProgram.data.path,
  [EnrollmentStateEnum.MatchPreferences]: ROUTES.enrollWizardProgram.data.path,
  [EnrollmentStateEnum.MatchRequest]: ROUTES.enrollWizardProgram.data.path,
  [EnrollmentStateEnum.MatchSuggestions]: ROUTES.enrollWizardProgram.data.path,
  [EnrollmentStateEnum.EnrollmentCompleted]: ROUTES.enrollWizardProgram.data.path,
};

const INVITATION_PATHS = {
  [EnrollmentStateEnum.Welcome]: ROUTES.enrollWizardWelcome.data.path,
  [EnrollmentStateEnum.PendingInvitations]: ROUTES.enrollWizardOverview.data.path,
};

const EnrollProvider: FC<EnrollProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const authState = useAppAuthState();
  const router = useAppRouter();
  const invitedProgram = useAppSelector(({ invitedPrograms }) =>
    getInvitedProgramSelector(invitedPrograms, authState.enrollment.meta?.programId ?? -1),
  );
  const invitedProgramsLoading = useAppSelector(({ invitedPrograms }) =>
    invitedProgramsLoadingStateSelector(invitedPrograms),
  );

  const redirect = useMemo(() => {
    if (authState.enrollment.state) {
      const invitationPaths = new Set(Object.values(INVITATION_PATHS));
      const enrollmentPaths = new Set(Object.values(ENROLLMENT_PATHS));
      const redirectPath = ENROLLMENT_PATHS[authState.enrollment.state];
      const routerPath = router.route.data.path;

      if (
        (router.requirements.enrolled && authState.enrollment.enrolling) ||
        (router.requirements.enrolled && authState.enrollment.invited && !invitationPaths.has(routerPath)) ||
        (enrollmentPaths.has(routerPath) && redirectPath !== routerPath)
      ) {
        return redirectPath;
      }
    }
  }, [
    router.route.data.path,
    authState.enrollment.invited,
    router.requirements.enrolled,
    authState.enrollment.enrolling,
    authState.enrollment.state,
  ]);

  useEffect(() => {
    if (authState.user?.id && authState.enrollment.state) {
      dispatch(
        getInvitedProgramsThunk({
          userId: authState.user.id,
        }),
      );
    }
  }, [dispatch, authState.user?.id, authState.enrollment.state]);

  const content = useMemo(() => {
    if (redirect) {
      return <AppRedirect path="*" to={redirect} />;
    }

    return children;
  }, [children, redirect]);

  return (
    <EnrollContextProvider
      user={authState.user}
      state={authState.enrollment.state}
      meta={authState.enrollment.meta}
      invited={authState.enrollment.invited}
      loading={authState.enrollment.loading || invitedProgramsLoading.isPending}
      program={invitedProgram}
      actions={{
        back: async () => {
          if (authState.user?.id) {
            await dispatch(
              getEnrollmentPreviousStateThunk({
                userId: authState.user.id,
              }),
            );
          }
        },
        skip: async () => {
          if (authState.user?.id) {
            const invitationsResponse = await dispatch(
              getInvitedProgramsThunk({
                userId: authState.user.id,
              }),
            );

            if (getInvitedProgramsThunk.fulfilled.match(invitationsResponse)) {
              const currentStateResponse = await dispatch(
                skipEnrollmentThunk({
                  userId: authState.user.id,
                }),
              );

              if (skipEnrollmentThunk.fulfilled.match(currentStateResponse)) {
                history.mq.push(ROUTES.myCliQ.data.path);
              }
            }
          }
        },
        next: async () => {
          if (authState.user?.id && authState.enrollment.state) {
            const response = await dispatch(
              getInvitedProgramsThunk({
                userId: authState.user.id,
              }),
            );

            if (getInvitedProgramsThunk.fulfilled.match(response)) {
              await dispatch(
                setEnrollmentNextStateThunk({
                  userId: authState.user.id,
                  data: {
                    currentState: authState.enrollment.state,
                  },
                }),
              );
            }
          }
        },
        save: async (callback) => {
          if (authState.user?.id) {
            const invitationsResponse = await dispatch(
              getInvitedProgramsThunk({
                userId: authState.user.id,
              }),
            );

            if (getInvitedProgramsThunk.fulfilled.match(invitationsResponse)) {
              const currentStateResponse = await dispatch(
                getEnrollmentCurrentStateThunk({
                  userId: authState.user.id,
                }),
              );

              if (getEnrollmentCurrentStateThunk.fulfilled.match(currentStateResponse)) {
                callback?.(invitationsResponse.payload);
              }
            }
          }
        },
        start: async (programId, roleIds) => {
          if (authState.user) {
            const response = await dispatch(
              startEnrollmentThunk({
                userId: authState.user.id,
                programId: programId,
                programRoleIds: roleIds,
              }),
            );

            if (startEnrollmentThunk.fulfilled.match(response)) {
              history.mq.push(ROUTES.enrollWizardProgram.data.path);
            }
          }
        },
      }}
    >
      {content}
    </EnrollContextProvider>
  );
};

interface EnrollContextProviderProps extends AppEnrollContextParams {
  children?: ReactNode;
}

const EnrollContextProvider: FC<EnrollContextProviderProps> = ({
  children,
  actions,
  state,
  meta,
  user,
  loading,
  program,
  invited,
}) => {
  const value = useMemo(
    () => ({
      state,
      user,
      meta,
      loading,
      program,
      invited,
      actions,
    }),
    [actions, invited, loading, meta, program, state, user],
  );

  return <AppEnrollContext.Provider value={value}>{children}</AppEnrollContext.Provider>;
};

export default EnrollProvider;
